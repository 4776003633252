import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MyContext } from '../App'
import { onValue, ref, set } from 'firebase/database'
import { db } from '../others/firebaseConfig'
import { Button, Icon, Image, Modal, Table } from 'semantic-ui-react'
import { generateDateTime } from '../others/datetime'

export default function Order() {
  const { id } = useParams()
  const { user, data, theme } = useContext(MyContext)
  const [order, setOrder] = useState({})
  const [img, setImg] = useState('')

  useEffect(() => {
    if (user) {
      onValue(ref(db, 'kgntilesknl/order/' + id), (snapshot) => {
        if (snapshot.exists()) {
          const val = snapshot.val()
          setOrder(val);
        }
      })
    }
  }, [user, id])
  const navigate = useNavigate();
  function deleteOrder() {
    set(ref(db, 'kgntilesknl/order/' + id), null)
    navigate("/Orders");
  }
  function completeOrder() {
    if (order.status !== 'pending') {
      return
    }
    set(ref(db, `kgntilesknl/order/${id}/status`), 'completed')
    let products = order.products
    for (const key in products) {
      if (Object.hasOwnProperty.call(products, key)) {
        const product = products[key];
        const items = product.items
        for (const i in items) {
          if (Object.hasOwnProperty.call(items, i)) {
            const item = items[i];
            let newQuantity = 0
            if (order.type === 'sale') {
              newQuantity = data[key].item[i].stock - item.quantity
            } else {
              newQuantity = data[key].item[i].stock + item.quantity
            }
            set(ref(db, `kgntilesknl/product/${key}/item/${i}/stock`), newQuantity)
          }
        }
      }
    }
  }



  return (
    <div>
      <Modal open={img.length > 0} onClose={() => setImg('')} closeIcon>
        <Modal.Header><br /></Modal.Header>
        <Modal.Content>
          <Image src={img} />
        </Modal.Content>
      </Modal>

      {Object.keys(order).length > 0 ?
        <>
          <h3>{order?.type.toUpperCase()} ORDER #{id} ({order.status.toUpperCase()})</h3>
          <p style={{ fontWeight: 'bold' }}>Created: {generateDateTime(order.timestamp * 1000)}</p>
          <Table className='ui celled striped unstackable table' inverted={theme}>
            <thead>
              <tr>
                <th>Image</th>
                <th>Product</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(order.products).map((product, index) =>
                <tr key={index}>
                  <td className='collapsing'>
                    <Image size='tiny' src={product[1].details.image} onClick={() => setImg(product[1].details.image)} />
                  </td>
                  <td>
                    <h3>{product[1].details.name}</h3>
                    <table className='very compact'>
                      <thead>
                        <tr><th>Item</th><th>Qty.</th></tr>
                      </thead>
                      <tbody>
                        {product[1].items.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{data[product[0]].item[index].name}</td>
                              <td>{item.quantity}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {order.status === 'pending' &&
            <>
              <Button color='green' icon labelPosition='left' onClick={completeOrder}><Icon name='check circle' size='large' />Complete Order</Button>
              <Button color='red' icon labelPosition='left' onClick={deleteOrder} ><Icon name='close' size='large' />Delete Order</Button>
            </>
          }
        </>
        :
        'Loading...'
      }
    </div>
  )
}
