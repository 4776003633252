import React, { useContext, useEffect, useState } from 'react'
import { MyContext } from '../App'
import { Card, Image, Grid, GridColumn, Button } from 'semantic-ui-react'
import { Link, useNavigate, useParams } from 'react-router-dom'

export default function Products() {
  const { data, cats } = useContext(MyContext)
  const { slug } = useParams()
  const [items, setItems] = useState([])
  const [series, setSeries] = useState([])
  const [active, setActive] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    Object.values(cats).forEach(parent => {
      Object.values(parent)[0].forEach((child) => {
        if (child.slug === slug) {
          setItems(child.items.split(','))
          setSeries(child.series)
        }
      })
    })
  }, [cats, slug])


  return (
    <>
      <div>
        <Button size='mini'
          onClick={() => setActive('')}
          color={active === '' ? 'blue' : 'grey'}
        >All</Button>
        {series.map((item) => (
          <Button size='mini' key={item}
            color={active === item ? 'blue' : 'grey'}
            onClick={() => setActive(item)}
          >{item}</Button>
        ))}
      </div>
      <h3>{slug.toUpperCase()}</h3>

      <Grid columns={2}>
        {items.map((id) => {
          const item = data[id]
          if (!item) {
            console.log('Item is undefined');
            return ''
          }
          if (active) {
            if (!item.spec.series.split(', ').includes(active)) {
              return ''
            }
          }
          return (<GridColumn key={id}>
            <Card onClick={() => navigate(`/Product/${id}`)}>
              <Image src={item?.image} wrapped ui={false} />
              <Card.Content>
                <Card.Header>{item?.name}</Card.Header>
              </Card.Content>
              {/* <Card.Content>
                {cart.hasOwnProperty(id) ? 'Added' : 'Add To Cart'}
                <Icon
                  name={cart.hasOwnProperty(id) ? 'check circle' : 'plus cart'}
                  color={cart.hasOwnProperty(id) ? 'green' : 'blue'}
                  onClick={() => addToCart(id)}
                  size='large' style={{ float: 'right' }} />
              </Card.Content> */}
            </Card>
          </GridColumn>)
        }
        )}
      </Grid>
    </>
  )
}
