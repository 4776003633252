import React, { useContext } from 'react'
import { Button, Card, Grid, Image } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'
import { MyContext } from '../App'
import { push, ref } from 'firebase/database'
import { db } from '../others/firebaseConfig'

export default function Categories() {
  const { cats } = useContext(MyContext)
  const navigate = useNavigate()
  function addProduct() {
    const newKey = push(ref(db, 'kgntilesknl/product')).key
    navigate(`/edit-product/${newKey}`);
  }

  return (
    <>
      <hr />
      <Button color='green' onClick={addProduct}>Add New Product</Button>
      {Object.entries(cats).map((cat, index) => (
        <React.Fragment key={index}>
          <h2>{cat[1].name}</h2>
          <Grid columns={2}>
            {cat[1].items.map((item) => (
              <Grid.Column key={item.slug}>
                <Card as={Link} to={`/category/${item.slug}`}>
                  <Image src={item.image} wrapped ui={false} />
                  <Card.Content>
                    <Card.Header>{item.name}</Card.Header>
                    <Card.Description>{item.size}</Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            ))}
          </Grid>
        </React.Fragment>
      ))}
    </>
  )
}
