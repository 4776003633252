import { onValue, ref } from 'firebase/database'
import React, { useContext, useEffect, useState } from 'react'
import { db } from '../others/firebaseConfig'
import { Button, Table } from 'semantic-ui-react'
import { generateDateTime } from '../others/datetime'
import { useNavigate } from 'react-router-dom'
import { MyContext } from '../App'

export default function Orders() {
  const { theme } = useContext(MyContext)
  const [orders, setOrders] = useState({})
  const navigate = useNavigate()
  const [arr, setArr] = useState([])
  const [users, setUsers] = useState({})
  useEffect(() => {
    onValue(ref(db, 'kgntilesknl/order'), (snapshot) => {
      if (snapshot.exists()) {
        const val = snapshot.val()
        const x = Object.entries(val)
        x.sort((a, b) => {
          return b[0] - a[0]
        })
        setOrders(val)
        setArr(x)
      }
    })
    onValue(ref(db, 'kgntilesknl/users'), (snapshot) => {
      if (snapshot.exists()) {
        const val = snapshot.val()
        setUsers(val)
      } else {
        setUsers({})
      }
    })
  }, [])
  let bg0 = '#ffdd9e'
  let bg1 = '#9cff9c'
  if (theme) {
    bg0 = '#c49200'
    bg1 = 'green'
  }

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>My Orders</h2>
      <hr />
      {Object.keys(orders).length > 0 ?
        <>
          <Table className='ui celled unstackable table' inverted={theme}>
            <tbody>
              {arr.map((item) => (
                // <tr key={item[0]}>
                <tr
                  key={item[0]}
                  style={{ backgroundColor: item[1].status === 'pending' ? bg0 : bg1 }}
                >
                  <td>
                    <h3>Order #{item[0]} ({item[1].status.toUpperCase()})</h3>
                    <p>{generateDateTime(item[1].timestamp * 1000)}
                      <br />
                      By <b style={{ fontStyle: 'italic' }}>{users[item[1].user]?.details?.name ? users[item[1].user]?.details?.name : users[item[1].user]?.details?.email}</b>
                    </p>
                  </td>
                  <td className='collapsing'>
                    <Button color='blue' onClick={() => navigate('/Order/' + item[0])}>Details</Button>
                  </td>
                </tr>
              )
              )}
            </tbody>
          </Table>
          <hr />
        </>
        :
        <h3 style={{ textAlign: 'center' }}>No orders</h3>
      }
    </div>
  )
}