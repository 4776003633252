import React, { useContext } from 'react'
import { Icon, Table } from 'semantic-ui-react'
import { MyContext } from '../App'

export default function WhatsApp() {
  const { theme } = useContext(MyContext)
  return (
    <>
      <h2>WhatsApp</h2>
      <Table inverted={theme} unstackable collapsing>
        <tbody>
          <tr>
            <td>+917730993166</td>
            <td onClick={() => window.open('https://wa.me/917730993166')}><Icon name='whatsapp' size='large' /></td>
            <td onClick={() => window.open('tel:917730993166')}><Icon name='phone' rotated='90' size='large' /></td>
          </tr>
          <tr>
            <td>+916304242902</td>
            <td onClick={() => window.open('https://wa.me/916304242902')}><Icon name='whatsapp' size='large' /></td>
            <td onClick={() => window.open('tel:916304242902')}><Icon name='phone' rotated='clockwise' size='large' /></td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
