import React, { useContext } from 'react'
import { Icon, Table } from 'semantic-ui-react'
import { MyContext } from '../App'

export default function WhatsApp() {
  const { theme } = useContext(MyContext)
  return (
    <>
      <h2>Contact</h2>
      <Table inverted={theme} unstackable collapsing>
        <tbody>
          <tr>
            <td onClick={() => window.open('https://wa.me/917730993166')}><Icon name='map marker alternate' size='large' /></td>
            <td>KGN Granites & Tiles, PLOT NO 77, SY NO 652/2, Vijayapuri, Nandyal Checkpost, Kurnool</td>
          </tr>
          <tr>
            <td><Icon name='file alternate' size='large' /></td>
            <td>GST: 37BMRPS8570D1ZC</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
