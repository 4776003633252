import { onValue, ref } from 'firebase/database'
import React, { useContext, useEffect, useState } from 'react'
import { db } from '../others/firebaseConfig'
import { Button, Table } from 'semantic-ui-react'
import { MyContext } from '../App'

export default function Catalogue() {
  const { theme } = useContext(MyContext)
  const [list, setList] = useState([])
  useEffect(() => {
    onValue(ref(db, 'kgntilesknl/catalouge'), (snapshot) => {
      if (snapshot.exists()) {
        setList(snapshot.val())
      } else {
        setList([])
      }
    })
  }, [])
  return (
    <>
      <React.Fragment>
        {list.map(cat => (<React.Fragment key={cat.name}>
          <h3>{cat.name}</h3>
          <Table inverted={theme} unstackable collapsing>
            <tbody>
              {cat.items.map((item) => (<tr key={item.name}>
                <td style={{ fontWeight: 'bold' }}>{item.name}</td>
                <td><a href={item.pdf} rel="noreferrer" target='_blank'><Button color='blue' inverted={theme}>Open PDF</Button></a></td>
              </tr>))}
            </tbody>
          </Table>
        </React.Fragment>))}
      </React.Fragment>
    </>
  )
}
