import { onValue, ref, set } from 'firebase/database';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Icon, Table, TextArea } from 'semantic-ui-react';
import { db } from '../others/firebaseConfig';
import { MyContext } from '../App';

export default function FetchTiles() {
  const { theme } = useContext(MyContext)
  const [text, setText] = useState('')
  const [list, setList] = useState([])
  const [products, setProducts] = useState({})
  const [loading, setLoading] = useState(0)
  useEffect(() => {
    onValue(ref(db, 'kgntilesknl/product'), (snapshot) => {
      if (snapshot.exists()) {
        setProducts(snapshot.val())
      } else {
        setProducts({})
      }
    })
  }, [])
  function readCSV() {
    setList(text.split(','))
  }

  function fetchAll() {
    list.forEach(item => {
      fetchTiles(item);
    })
  }

  function fetchTiles(id) {
    if (!id) return
    setLoading(id)
    setTimeout(() => {
      fetch('http://localhost:4000/?id=' + id)
        .then(res => res.text())
        .then(htmlString => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(htmlString, 'text/html');
          let product = {}
          product['image'] = doc.querySelector('img').src;
          product['name'] = doc.querySelector('.js-name-detail').textContent.trim();
          product['price'] = doc.querySelector('.mtext-106.cl2').textContent.trim();
          product['item'] = []
          doc.querySelector('.table.mt-3 tbody').childNodes.forEach(tr => {
            if (tr.childNodes[1] && tr.childNodes[3]) {
              product['item'].push({
                name: tr.childNodes[1].textContent.trim(),
                stock: tr.childNodes[3].textContent.trim().split(' ')[0]
              })
            }
          });
          let spec = {}
          let key = ''
          let value = ''
          doc.querySelector('.table.mt-2 tbody').childNodes.forEach(tr => {
            if (tr.childNodes[1] && tr.childNodes[3]) {
              key = tr.childNodes[1].textContent.trim().replace(/:$/, '').toLowerCase().replace(/\s+/g, '_')
              value = tr.childNodes[3].textContent.trim()
              spec[key] = value
            }
          });
          product['spec'] = spec
          set(ref(db, 'kgntilesknl/product/' + id), product);
        })
        .finally(() => setLoading(0))
    }, 2000);
  }
  return (
    <div>
      <h2>Enter the IDs of TuchBuy: (csv)</h2>
      <Form inverted={theme}>
        <TextArea style={{ fontSize: 18 }} cols="40" rows="5" value={text} onChange={e => setText(e.target.value)}></TextArea>
      </Form>
      <br />
      <Button color='blue' onClick={readCSV}>Generate Table</Button>
      <Button color='green' onClick={fetchAll}>Fetch All</Button>
      <hr />
      <Table inverted={theme} className='ui unstackable collapsing table'>
        <tbody>
          {list.map(item => <tr key={item}>
            <td>{item}</td>
            <td>
              <Button color='orange' inverted={theme} onClick={() => fetchTiles(item)} loading={loading === item}>Fetch</Button>
            </td>
            <td>
              {products[item] ?
                <Icon inverted={theme} name='check circle outline' color='green' size='large' />
                :
                <Icon inverted={theme} name='close' color='red' size='large' />
              }
            </td>
          </tr>)}
        </tbody>
      </Table>
    </div>
  )
}
