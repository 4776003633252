import { createContext, useEffect, useState } from 'react';
import { Button, Container, Icon, Menu, Segment } from 'semantic-ui-react';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, onIdTokenChanged, signOut } from "firebase/auth";
import Products from './components/Products';
import Cart from './components/Cart'
import InstallButton from './others/InstallButton';
import { Link, Routes, Outlet, HashRouter, Route, useLocation } from 'react-router-dom'
import { onValue, ref, set } from 'firebase/database';
import { db } from './others/firebaseConfig';
import Orders from './components/Orders';
import Profile from './components/Profile';
import Order from './components/Order';
import Product from './components/Product';
import Categories from './components/Categories';
import FetchTiles from './components/FetchTiles';
import WhatsApp from './components/WhatsApp';
import Contact from './components/Contact';
import Catalogue from './components/Catalogue';
import Stock from './components/Stock';
import EditProduct from './components/EditProduct';

const loginbg = require('./images/account.png')

export const MyContext = createContext(null)

export default function App() {

  const menudata = [
    { name: 'Home', icon: 'home' },
    { name: 'Cart', icon: 'cart' },
    { name: 'Orders', icon: 'list' },
    { name: 'Profile', icon: 'user' },
  ]

  const [data, setData] = useState([])
  const [cats, setCats] = useState({})
  const [details, setDetails] = useState(null)
  const [cart, setCart] = useState({})
  const [user, setUser] = useState(null)
  const [url, setUrl] = useState('/')
  const [load, setLoad] = useState(false)
  let oldTheme = false
  if (localStorage.getItem('theme')) {
    oldTheme = JSON.parse(localStorage.getItem('theme'))
  }
  const [theme, setTheme] = useState(oldTheme)
  useEffect(() => {
    document.body.style.backgroundColor = theme ? 'black' : 'white'
    localStorage.setItem('theme', JSON.stringify(theme))
  }, [theme])

  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    const unsubscribeAuthState = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    const unsubscribeIdToken = onIdTokenChanged(auth, (user) => {
      setUser(user);
    });
    return () => {
      unsubscribeAuthState();
      unsubscribeIdToken();
    };
  }, [auth]);

  useEffect(() => {
    if (user) {
      const cartRef = ref(db, 'kgntilesknl/users/' + user.uid + '/cart')
      onValue(cartRef, (snapshot) => {
        const val = snapshot.val()
        if (val) {
          setCart(val)
        } else {
          setCart([])
        }
      })
    }
  }, [user])

  useEffect(() => {
    if (user && load) {
      set(ref(db, 'kgntilesknl/users/' + user.uid + '/cart'), cart)
      // let cart_total = 0
      // Object.entries(cart).forEach((item) => {
      //   cart_total += parseInt(data[item[0]].price * item[1].quantity)
      // })
      // console.log(cart_total);
      // set(ref(db, 'kgntilesknl/users/' + user.uid + '/cart_total'), cart_total)
    }
  }, [cart, user, load])

  useEffect(() => {
    onValue(ref(db, 'kgntilesknl/product'), (snapshot) => {
      setData(snapshot.val())
    })
    onValue(ref(db, 'kgntilesknl/category'), (snapshot) => {
      setCats(snapshot.val())
    })
    if (user) {
      onValue(ref(db, 'kgntilesknl/users/' + user.uid + '/details'), (snapshot) => {
        setDetails(snapshot.val())
      })
      onValue(ref(db, 'kgntilesknl/users/' + user.uid + '/cart'), (snapshot) => {
        const val = snapshot.val()
        setLoad(true)
        if (val) {
          setCart(val)
        } else {
          setCart({})
        }
      })
    }
  }, [user])

  function doSignOut() {
    signOut(auth).then(() => {
      window.location.reload()
    }).catch((error) => {
      console.error(error);
    });
  }

  function dosignin() {
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user)
      }).catch((error) => {
        console.log(error)
      });
  }

  function Navigation() {
    return (<>
      <div>
        {user ?
          <Menu icon='labeled' compact widths={menudata.length + 2} size='mini' inverted={theme}>
            {menudata.map((item) =>
              <Menu.Item
                key={item.name}
                name={item.name}
                as={Link}
                to={item.name}
                active={url === '/' + item.name ? true : false}
              // active={page === item.name}
              // onClick={handleItemClick}
              // color={page === item.name ? 'blue' : 'black'}
              >
                <Icon name={item.icon} />
                {item.name}
              </Menu.Item>
            )}
            <Menu.Item onClick={() => setTheme(!theme)}>
              <Icon name={theme ? 'moon' : 'sun outline'} />{theme ? 'Dark' : 'Light'}
            </Menu.Item>
            <Menu.Item color='red'
              name={`logout`}
              onClick={doSignOut}
            >
              <Icon name={`sign-out`} color='red' />
              Logout
            </Menu.Item>
          </Menu>
          :
          <><br /><br /></>
        }

        <Segment inverted={theme}>
          <div style={{ backgroundColor: 'rgba(ff, ff, ff, 1)' }}>
            {user ?
              <>
                <Outlet />
              </>
              :
              <Container>
                <div style={{ textAlign: 'center' }}>
                  <img src={loginbg} alt="" style={{ width: 300 }} />
                  <h2>Join Now</h2>
                  <Button color='green' icon labelPosition='left' onClick={dosignin}>
                    <Icon name='google' />Login with Google
                  </Button>
                  <br />
                  <br />
                  <InstallButton />
                </div>
              </Container>
            }
          </div>
        </Segment>
      </div>
    </>)
  }
  function YourComponent() {
    const location = useLocation();
    useEffect(() => {
      setUrl(location.pathname);
    }, [location]);
  }

  function HomeMenu() {
    return (<>
      <div style={{ overflowX: 'auto' }}>
        <Button size='mini' color={url === '/' || url === '/Home' ? 'blue' : 'grey'} as={Link} to="/Home">Home</Button>
        <Button size='mini' color={url === '/catalogue' ? 'blue' : 'grey'} as={Link} to="/catalogue">Catalogue</Button>
        <Button size='mini' color={url === '/whatsapp' ? 'blue' : 'grey'} as={Link} to="/whatsapp">WhatsApp</Button>
        <Button size='mini' color={url === '/contact' ? 'blue' : 'grey'} as={Link} to="/contact">Contact</Button>
      </div>
      <Outlet />
    </>)
  }

  return (
    <MyContext.Provider value={{ user, setUser, cart, setCart, data, setData, details, setDetails, theme, cats }}>
      <HashRouter basename="">
        <Routes>
          <Route path="" element={<><Navigation /><YourComponent /></>}>
            <Route path="" element={<HomeMenu />}>
              <Route path="" element={<Categories />} />
              <Route path="/Home" element={<Categories />} />
              <Route path="catalogue" element={<Catalogue />} />
              <Route path="whatsapp" element={<WhatsApp />} />
              <Route path="contact" element={<Contact />} />
            </Route>

            <Route path="category/:slug" element={<Products />} />
            <Route path="edit-product/:id" element={<EditProduct />} />
            <Route path="/Product/:id" element={<Product />} />
            <Route path="Cart" element={<Cart />} />
            <Route path="Orders" element={<Orders />} />
            <Route path="Order/:id" element={<Order />} />
            <Route path="Profile" element={<Profile />} />
            <Route path="stock" element={<Stock />} />
            <Route path="FetchTiles" element={<FetchTiles />} />
          </Route>
        </Routes>
      </HashRouter>
    </MyContext.Provider>
  )
}

