import { onValue, push, ref, remove, set } from 'firebase/database'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, FormField, Icon, Input, Modal, Select, Table } from 'semantic-ui-react'
import { db } from '../others/firebaseConfig'
import { useNavigate, useParams } from 'react-router-dom'
import { MyContext } from '../App'

export default function EditProduct() {
  const [product, setProduct] = useState(null)
  const { id } = useParams()
  const { theme, cats } = useContext(MyContext)
  const dummySpec = { coverage_area: "", manufacturer: "", series: "", size: "", thickness: "", units_per_box: "", weight: "" }
  const [name, setName] = useState('')
  const [image, setImage] = useState('')
  const [item, setItem] = useState([])
  const [spec, setSpec] = useState(dummySpec)
  const [category, setCategory] = useState('')
  const [catopts, setCatopts] = useState([])
  const [seriesopts, setSeriesopts] = useState([])
  const [loading, setLoading] = useState(false)
  const [fakePath, setFakePath] = useState(null)

  const navigate = useNavigate()
  const [del, setDel] = useState('')

  useEffect(() => {
    onValue(ref(db, `kgntilesknl/product/${id}`), (snapshot) => {
      if (snapshot.exists()) {
        const val = snapshot.val()
        setName(val.name || '')
        setImage(val.image || '')
        setItem(val.item || [{ name: '', stock: 0 }])
        setSpec(val.spec || dummySpec)
      } else {
        setName('')
        setImage('')
        setItem([{ name: '', stock: 0 }])
        setSpec(dummySpec)
      }
    })
  }, [])

  useEffect(() => {
    let allitems = {}
    let parent = []
    let child = []
    if (cats) {
      for (const key in cats) {
        const sub = cats[key]
        parent.push({ name: sub.name, value: key, text: sub.name });
        for (const i in sub.items) {
          const list = sub.items[i].items.split(',')
          allitems[`${key}_${i}`] = list
          if (list.includes(id)) {
            setCategory(`${key}_${i}`)
            saveProduct()
            let opts = []
            for (const s in sub.items[i].series) {
              let ele = sub.items[i].series[s]
              opts[s] = { key: ele, value: ele, text: ele }
            }
            setSeriesopts(opts)
          }
          child.push({ key: `${key}_${i}`, value: `${key}_${i}`, text: sub.items[i].name })
        }
      }
    }
    setCatopts(child)
  }, [])

  function saveProduct(force = '') {
    if (category || force) {
      setLoading(true)
      set(ref(db, `kgntilesknl/product/${id}`), { name, image, item, spec: spec })
        .then(() => setLoading(false))
    }
  }
  function addRow() {
    let newItem = [...item]
    newItem.push({ name: '', stock: 0 })
    setItem(newItem)
  }
  function removeRow(i) {
    let newItem = [...item]
    newItem.splice(i, 1)
    setItem(newItem)
  }
  function updateSpec(e, d) {
    let newSpec = { ...spec }
    if (d) {
      newSpec[d.name] = d.value
    } else {
      newSpec[e.target.name] = e.target.value
    }
    setSpec(newSpec)
  }
  function updateItem(e, key) {
    const i = e.target.name
    let newItem = [...item]
    newItem[i][key] = e.target.value
    setItem(newItem)
  }
  function removeCategory() {
    const opts = category.split('_')
    let items = cats[opts[0]]['items'][opts[1]].items.split(',')
    var index = items.indexOf(id);
    if (index !== -1) {
      items.splice(index, 1);
    }
    set(ref(db, `kgntilesknl/category/${opts[0]}/items/${opts[1]}/items`), items.join(','))
  }
  function updateCategory(e, d) {
    const newCat = d.value
    if (category) {
      removeCategory()
    }
    const newopts = newCat.split('_')
    let newitems = cats[newopts[0]]['items'][newopts[1]].items.split(',')
    newitems.push(id)
    set(ref(db, `kgntilesknl/category/${newopts[0]}/items/${newopts[1]}/items`), newitems.join(','))
    setCategory(newCat)
    saveProduct(true)
    console.log('I am running');
  }
  function deleteProduct() {
    if (id) {
      removeCategory()
      set(ref(db, `kgntilesknl/product/${id}`), null)
      if (category) {
        const opts = category.split('_')
        const slug = cats[opts[0]]['items'][opts[1]].slug
        navigate('/category/' + slug)
      } else {
        navigate('/Home')
      }
    }
  }
  async function uploadImage(e) {
    e.preventDefault();
    let headersList = {
      "Accept": "*/*",
    }
    let bodyContent = new FormData();
    bodyContent.append("file", e.target.fileInput.files[0]);
    setLoading(true)
    let response = await fetch("https://kgntiles-com.stackstaging.com/wp-json/image-via-api/v1/upload-image/", {
      method: "POST",
      body: bodyContent,
      headers: headersList
    });

    let data = await response.json();
    setLoading(false)
    setFakePath(null)
    if (response.status === 200) {
      setImage(data)
      set(ref(db, `kgntilesknl/product/${id}/image`), data)
    }
  }
  return (
    <div>
      <Modal open={del !== ''} onClose={() => setDel('')}>
        <Modal.Header>Are you sure to delete this product?</Modal.Header>
        <Modal.Actions>
          <Button color='red' onClick={deleteProduct}>Delete</Button>
          <Button color='black' onClick={() => setDel('')}>Cancel</Button>
        </Modal.Actions>
      </Modal>

      <Modal open={fakePath !== null} onClose={() => setFakePath(null)} closeIcon>
        <Modal.Header>Upload New Image</Modal.Header>
        <Modal.Content>
          <form onSubmit={uploadImage} encType='multipart/formdata'>
            <FormField>
              <label htmlFor="photo">Photo</label>
              <Input type="file" name="fileInput" id="photo" onChange={e => setFakePath(e.target.value)} />
            </FormField>
            {fakePath ? <Button color='green' disabled={loading} loading={loading}>Upload</Button> : ''}
          </form>
        </Modal.Content>
      </Modal>
      <h2>{category.length > 0 ? 'Edit' : 'Add'} Product Details <Button onClick={() => setDel(id)} color='red'>Delete</Button></h2>
      <Form inverted={theme}>
        <Form.Group widths={3}>
          <Form.Field>
            <label>Select Category</label>
            <Select options={catopts} value={category} onChange={updateCategory} />
          </Form.Field>
          {category.length > 0 &&
            <>
              <Form.Field>
                <label>Series</label>
                <Select options={seriesopts} name='series' value={spec.series} onChange={updateSpec} />
              </Form.Field>
              <Form.Field>
                <label>Product Name</label>
                <Input value={name} onChange={e => setName(e.target.value)} />
              </Form.Field>
            </>
          }
        </Form.Group>
        {category.length > 0 &&
          <Table collapsing unstackable compact inverted={theme}>
            <tbody>
              <tr>
                <td>
                  <FormField>
                    <label>Image:</label>
                    <Input value={image} onChange={e => setImage(e.target.value)} />
                    <Button color='blue' onClick={() => setFakePath('')}>Upload New</Button>
                  </FormField>
                </td>
                <td>
                  <img src={image} alt="" style={{ width: '100%', maxWidth: 300 }} />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      </Form>
      {category.length > 0 &&
        <>
          <h2>Items <Icon name='plus circle' color='green' inverted={theme} onClick={addRow} /></h2>
          <Table collapsing unstackable compact inverted={theme}>
            <thead>
              <tr>
                <th style={{ width: '60%' }}>Item Name</th>
                <th>Stock</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {item.map((row, i) => (<tr key={i}>
                <td><Input fluid value={row.name} onChange={(e) => updateItem(e, 'name')} name={i} /></td>
                <td className='collapsing'><Input type='number' max="9999" min="0" value={row.stock} onChange={(e) => updateItem(e, 'stock')} name={i} />
                </td>
                <td className='collapsing'>
                  <Icon name='minus circle' size='large' color='red' inverted={theme}
                    onClick={() => removeRow(i)}
                  />
                </td>
              </tr>))}
            </tbody>
          </Table>

          <h2>Specifications</h2>
          <Table collapsing unstackable inverted={theme} compact="very">
            <tbody>
              <tr>
                <td>Coverage Area</td>
                <td>
                  <Input value={spec.coverage_area} onChange={updateSpec} name='coverage_area' />
                </td>
              </tr>
              <tr>
                <td>Manufacturer</td>
                <td>
                  <Input value={spec.manufacturer} onChange={updateSpec} name='manufacturer' />
                </td>
              </tr>
              <tr>
                <td>Size</td>
                <td>
                  <Input value={spec.size} onChange={updateSpec} name='size' />
                </td>
              </tr>
              <tr>
                <td>Thickness</td>
                <td>
                  <Input value={spec.thickness} onChange={updateSpec} name='thickness' />
                </td>
              </tr>
              <tr>
                <td>Units per Box</td>
                <td>
                  <Input value={spec.units_per_box} onChange={updateSpec} name='units_per_box' />
                </td>
              </tr>
              <tr>
                <td>Weight</td>
                <td>
                  <Input value={spec.weight} onChange={updateSpec} name='weight' />
                </td>
              </tr>
            </tbody>
          </Table>
          <Button fluid color='green' loading={loading} onClick={saveProduct}>Save Product Details</Button>
        </>
      }
    </div>
  )
}