import { onValue, ref, set } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { db } from '../others/firebaseConfig'

export default function Stock() {
  const [list, setList] = useState({})
  useEffect(() => {
    onValue(ref(db, 'kgntilesknl/product'), (snapshot) => {
      if (snapshot.exists()) {
        setList(snapshot.val())
      } else {
        setList({})
      }
    })
  }, [])
  function resetAllStock() {
    for (const key in list) {
      if (list.hasOwnProperty.call(list, key)) {
        const product = list[key];
        for (const i in product.item) {
          set(ref(db, `kgntilesknl/product/${key}/item/${i}/stock`), 0)
        }
        // console.log('updated ' + key);
      }
    }
    window.alert('All stock reset successfully.')
  }
  return (
    <div>
      <Button onClick={resetAllStock}>Reset all stock</Button>
    </div>
  )
}
