import React, { useContext, useEffect, useState } from 'react'
import { Form, Input, Button, Icon } from 'semantic-ui-react';
import { ref, set } from 'firebase/database';
import { MyContext } from '../App';
import { db } from '../others/firebaseConfig';

export default function Profile({ category, setCategory, amount }) {
  const { user, details, theme } = useContext(MyContext)

  const [name, setFullName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [pincode, setPincode] = useState('')

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user && details) {
      setPhone(details?.phone)
      setFullName(details?.name)
      setAddress(details?.address)
      setCity(details?.city)
      setPincode(details?.pincode)
    }
  }, [user, details])

  function saveData() {
    const userData = { name, email: user.email, phone, address, city, pincode }
    const userRef = ref(db, 'kgntilesknl/users/' + user?.uid + '/details')
    if (!name || !phone || !address || !city || !pincode) {
      alert('Enter all details properly.')
      return
    }
    setLoading(true)
    set(userRef, userData)
      .then(() => { })
      .catch((error) => console.error(error))
      .finally((() => setLoading(false)))
  }

  return (
    <div className='ui container'>

      <Form inverted={theme} className="ui form">

        <Form.Group widths='equal'>
          <Form.Field>
            <label>Full Name</label>
            <Input type="text" name="fullname" placeholder="Full Name"
              value={name}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Form.Field>

          <Form.Field>
            <label>Phone <i>(10 digits)</i></label>
            <Input type="text" name="phone" placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value.trim())}
            />
          </Form.Field>

          <Form.Field>
            <label>Email <i>(readonly)</i></label>
            <Input type="email" name="email" placeholder="Email"
              readOnly={true}
              value={user?.email}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Field>
            <label>Address</label>
            <Input type="text" name="adderss" placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Field>

          <Form.Field>
            <label>City</label>
            <Input type="text" name="city" placeholder="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Form.Field>

          <Form.Field>
            <label>PINCODE</label>
            <Input type="pincode" name="pincode" placeholder="PINCODE"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
          </Form.Field>
        </Form.Group>

      </Form>

      <Button className="ui button" type="button" color='blue' labelPosition='right' icon
        onClick={saveData}
        loading={loading}
        disabled={loading}
      >
        Save & Continue <Icon name='checkmark' />
      </Button>
    </div>
  )
}
