import React, { useContext, useEffect, useState } from 'react'
import { MyContext } from '../App'
import { Button, Icon, Image, List, Message, Modal } from 'semantic-ui-react'
import { limitToLast, onValue, query, ref, set } from 'firebase/database'
import { db } from '../others/firebaseConfig'
import { useNavigate } from 'react-router-dom'

export default function Cart() {
  const { data, cart, setCart, user } = useContext(MyContext)
  // const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [lastId, setLastId] = useState(0)
  const [img, setImg] = useState('')

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (user && cart) {
  //     onValue(ref(db, 'kgntilesknl/users/' + user.uid + '/cart_total'), (snapshot) => {
  //       const val = snapshot.val()
  //       setTotal(val)
  //     })
  //   }
  // }, [user])

  useEffect(() => {
    if (user && cart) {
      const nodeRef = query(ref(db, 'kgntilesknl/order'), limitToLast(1))
      onValue(nodeRef, (snapshot) => {
        if (snapshot.exists()) {
          const val = snapshot.val()
          setLastId(parseInt(Object.keys(val)[0]))
        }
      })
    }
  }, [cart, user])

  function changeQuantity(id, item, value) {
    let newCart = { ...cart }
    let obj = newCart[id]
    obj[item] = { quantity: obj[item].quantity + value }

    if (value === -1 && newCart[id].quantity === 0) {

    } else {
      newCart[id] = obj
    }
    setCart(newCart)
  }
  function removeItem(id, item) {
    let newCart = { ...cart }
    delete newCart[id][item]
    setCart(newCart)
  }

  function placeOrder(type) {
    let final = {}
    for (const key in cart) {
      if (Object.hasOwnProperty.call(cart, key)) {
        let items = {}
        const product = cart[key];
        for (const i in product) {
          if (Object.hasOwnProperty.call(product, i)) {
            const item = { quantity: product[i].quantity }
            items[i] = item
          }
        }
        final[key] = { details: data[key], items }
      }
    }
    const order = {
      type,
      user: user.uid,
      products: final,
      status: 'pending',
      timestamp: Math.floor(Date.now() / 1000),
    }

    setLoading(true)
    const newId = lastId + 1
    set(ref(db, `kgntilesknl/order/${newId}`), order)
      .then(() => {
        setShow(true)
        setTimeout(() => {
          setCart({})
          setLoading(false)
          navigate(`/Order/${newId}`);
        }, 2000);
      }).catch(err => console.log(err))
  }
  return (
    <div>
      <Modal open={img.length > 0} onClose={() => setImg('')} closeIcon>
        <Modal.Header><br /></Modal.Header>
        <Modal.Content>
          <Image src={img} />
        </Modal.Content>
      </Modal>

      <Message
        hidden={!show}
        // visible={show}
        icon={<Icon name='circle notched' loading />}
        color='green'
        header='Order placed'
        content='Go to orders page to see all orders'
      />
      <h2 style={{ textAlign: 'center' }}>Cart</h2>
      <hr />
      {Object.keys(cart).length > 0 ?
        <>
          <List verticalAlign='middle'>
            {Object.entries(cart).map((product) => (
              <List.Item>
                <h2>{data[product[0]].name}</h2>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 25, justifyContent: 'space-between', alignItems: 'center' }}>
                  <Image size='tiny' src={data[product[0]].image} onClick={() => setImg(data[product[0]].image)} />
                  <div style={{ flexGrow: 1 }}>

                    {Object.entries(product[1]).map((item) => (
                      <React.Fragment key={item[0]}>
                        <h3 style={{ marginBottom: 5, marginTop: 10 }}>{data[product[0]].item[item[0]]?.name}</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                          <Icon name='minus circle' size='large' color='red' onClick={() => changeQuantity(product[0], item[0], -1)}
                            disabled={item[1].quantity === 0}
                          />
                          <span>{item[1].quantity}</span>
                          <Icon name='plus circle' size='large' color='green' onClick={() => changeQuantity(product[0], item[0], 1)} />
                          <Icon name='delete' size='large' color='red' onClick={() => removeItem(product[0], item[0])} />
                        </div>
                      </React.Fragment>
                    ))}

                    {/* <br />
                    <br />
                    <div style={{ fontSize: 20, textAlign: 'center' }}>Rs.{data[item[0]].price * item[1].quantity}</div> */}
                  </div>
                </div>
                <hr />
              </List.Item>
            )
            )}

            {/* <List.Item>
              <div style={{ textAlign: 'right', fontSize: 20, paddingTop: 10 }}>Total: Rs.{total}</div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 25, justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flexGrow: 1 }}>
                </div>
                <div style={{ fontSize: 20 }}></div>
              </div>
            </List.Item> */}
          </List>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button color='blue' fluid size='large' disabled={loading} content='Sale Order' icon='minus square' labelPosition='left' onClick={() => placeOrder('sale')} />
            <Button color='teal' fluid size='large' disabled={loading} content='Purchase Order' icon='plus' labelPosition='left' onClick={() => placeOrder('purchase')} />
            <Icon name='' />
          </div>
        </>
        :
        <h3 style={{ textAlign: 'center' }}>No items in the cart</h3>
      }
    </div>
  )
}
