import React, { useContext, useState } from 'react'
import { Card, Icon, Image, Button, Segment, Header, Table, Modal, Input } from 'semantic-ui-react'
import { MyContext } from '../App'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ref, set } from 'firebase/database'
import { db } from '../others/firebaseConfig'


export default function Product(p) {
  const { cart, setCart, data, theme } = useContext(MyContext)
  const [product, setProduct] = useState(null)
  const params = useParams()
  let id = 0
  if (p.id) {
    id = p.id
  } else {
    id = params.id
  }

  function addToCart(id) {
    let newCart = { ...cart }
    if (cart.hasOwnProperty(id)) {
      delete newCart[id]
    } else {
      let item = {}
      for (const key in data[id].item) {
        item[key] = { quantity: 1 }
      }
      newCart[id] = item
    }
    setCart(newCart)
  }

  function editStock(id) {
    const x = JSON.parse(JSON.stringify(data[id]))
    setProduct(x)
  }
  function updateStock() {
    if (!product || !id) return
    set(ref(db, `kgntilesknl/product/${id}`), product)
      .then(() => setProduct(null))
  }
  if (Object.keys(data).length === 0) {
    return
  }
  return (
    <Card fluid>
      <Modal open={product !== null} closeIcon
        onClose={() => setProduct(null)}
      >
        <Modal.Header>Update Stock</Modal.Header>
        <Modal.Content>
          <Table inverted={theme} unstackable collapsing>
            <thead>
              {product && Object.values(product.item).map((row, i) => (<tr key={i}>
                <td>{row.name}</td>
                <td><Input type='number' value={row.stock}
                  onChange={e => setProduct(p => {
                    let newP = { ...p }
                    newP.item[i].stock = e.target.value
                    return newP
                  })}
                /></td>
              </tr>))}
            </thead>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={updateStock}>Save Changes</Button>
          <Button color='black' onClick={() => setProduct(null)}>Cancel</Button>
        </Modal.Actions>
      </Modal>
      <Image src={data[id].image} wrapped ui={false} />
      <Segment inverted={theme} style={{ margin: 0 }}>
        <Card.Content>
          <h2>{data[id].name}</h2>
          <Button onClick={() => editStock(id)} color='orange'>Update Stock</Button>
          <Button as={Link} to={`/edit-product/${id}`} color='blue'>Edit</Button>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: 15, paddingBottom: 15 }}>
            <span>{cart.hasOwnProperty(id) ? 'Added' : 'Add To Cart'}</span>
            <Icon
              inverted={theme}
              name={cart.hasOwnProperty(id) ? 'check circle' : 'plus cart'}
              color={cart.hasOwnProperty(id) ? 'green' : 'blue'}
              onClick={() => addToCart(id)}
              size='big' />
            <Icon
              inverted={theme}
              name='whatsapp'
              color='green'
              onClick={() => window.open(`https://wa.me/?text=${encodeURIComponent(window.location.href)}`)}
              size='big' />
          </div>
          <hr />
          <Table inverted={theme} unstackable collapsing>
            <thead>
              <tr>
                <th>Name</th>
                <th>Stock</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(data[id].item).map((row) => (
                <tr key={row.name}><td>{row.name}</td><td>{row.stock}</td></tr>
              ))}
            </tbody>
          </Table>
          <h4>Specifications</h4>
          <Table inverted={theme} unstackable collapsing>
            <tbody>
              {Object.entries(data[id].spec).map((row) => (
                <tr key={row[0]}><td>{row[0].toUpperCase().replaceAll('_', ' ')}</td><td>{row[1]}</td></tr>
              ))}
            </tbody>
          </Table>

          <Header as='h4' inverted={theme}>

          </Header>
        </Card.Content>
      </Segment>
    </Card>
  )
}
